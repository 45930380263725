/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useCompleteFTUEStepMutation } from '@/store/onboarding/mutations';
import { FTUEStepDto } from '@/store/user-profile/types';
import { getActiveFtueStep } from '@/components/teacher/FTUE/constants';
import { useUserProfileContext } from './UserProfileContext';
import { useGlobalContext } from './GlobalContext';
import { getRoleGroup } from './AuthContext/helpers';

type FTUEContextType = {
  FTUEsteps: FTUEStepDto[];
  activeFTUEStep: number;
  onCompleteStep: (step: number) => void;
};

export const FTUEContext = createContext<FTUEContextType>({
  FTUEsteps: [],
  activeFTUEStep: 0,
  onCompleteStep: () => {},
});

const FTUEProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const { state: user, dispatch } = useUserProfileContext();
  const groupRole = getRoleGroup(user.type);
  const { setIsRightDrawerOpen, setIsLeftDrawerOpen } = useGlobalContext();
  const [isSteps, setIsSteps] = useState(false);
  const [activeFTUEStep, setActiveFTUEStep] = useState(0);
  const FTUEsteps = useMemo(() => user.steps, [user.steps]);

  useEffect(() => {
    if (FTUEsteps?.length) {
      setIsSteps(true);
    }
  }, [FTUEsteps]);

  useEffect(() => {
    if (!isSteps) {
      return;
    }

    const currentActiveFTUEStep = getActiveFtueStep(FTUEsteps);

    if (currentActiveFTUEStep && groupRole === 'teacher') {
      setActiveFTUEStep(currentActiveFTUEStep);
      if (
        currentActiveFTUEStep === 1 ||
        currentActiveFTUEStep === 3 ||
        currentActiveFTUEStep === 5 ||
        currentActiveFTUEStep === 6
      ) {
        if (currentActiveFTUEStep === 1) {
          setIsRightDrawerOpen(false);
          setIsLeftDrawerOpen(false);
        } else {
          setIsRightDrawerOpen(true);
          setIsLeftDrawerOpen(false);
        }
        router.push({
          pathname: '/teacher/dashboard',
          query: {
            drawerType: 'teacherStudyGroups',
            selected: 'Dashboard',
            tab: 'LearningGoals',
            offset: 1,
          },
        });
      }

      if (currentActiveFTUEStep === 2) {
        setIsRightDrawerOpen(false);
        setIsLeftDrawerOpen(false);
        router.push({
          pathname: '/teacher/study-groups',
          query: {
            selected: 'Students',
            drawerType: 'idle',
            tab: 'AllStudents',
            selectedGroupCode: router.query.selectedGroupCode,
            subjectCode: router.query.subjectCode,
          },
        });
      }

      if (currentActiveFTUEStep === 3) {
        setIsRightDrawerOpen(true);
        router.push({
          pathname: '/teacher/dashboard',
          query: {
            selectedGroupCode: router.query.selectedGroupCode,
            subjectCode: router.query.subjectCode,
            drawerType: 'teacherStudyGroups',
            selected: 'Dashboard',
            tab: 'LearningGoals',
            offset: 1,
          },
        });
      }

      if (
        currentActiveFTUEStep === 6 ||
        currentActiveFTUEStep === 7 ||
        currentActiveFTUEStep === 8
      ) {
        setIsRightDrawerOpen(true);
        router.push({
          pathname: '/teacher/assignments',
          query: {
            selected: 'Launchpad',
            drawerType: 'idle',
            tab: 'AssignmentBuilder',
          },
        });
      }
    }
  }, [isSteps]);

  const { mutate: completeFTUEStep } = useCompleteFTUEStepMutation((data) => {
    dispatch({
      type: 'UPDATE_FTUE_STEPS',
      payload: data?.steps,
    });

    const aFTUEStep = getActiveFtueStep(data?.steps);
    setActiveFTUEStep(aFTUEStep || 0);
  });

  const onCompleteStep = async (step: number) => {
    completeFTUEStep(step);
  };

  const contextValue = useMemo(
    () => ({
      FTUEsteps,
      activeFTUEStep,
      onCompleteStep,
    }),
    [FTUEsteps, activeFTUEStep]
  );

  return (
    <FTUEContext.Provider value={contextValue}>{children}</FTUEContext.Provider>
  );
};

export const useFTUEContext = () => useContext(FTUEContext);

export { FTUEProvider };
